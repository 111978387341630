<template>
  <div class="container">
    <van-nav-bar
        left-text="办公环境"
        left-arrow fixed placeholder
        @click-left="$router.back()"
        v-if="isShow"
    />
    <div class="main">
      <h2>公司环境展示</h2>
      <p class="info">新明辉安全科技有限公司，总部位于临沂市，在新疆乌鲁木齐和四川成分别设有分公司。在临沂市，目前拥有四大办公区，员工500余人，基于新明辉“共赢、务实、简单、快速、开放”的文化理念，与公司同成长、共发展。</p>
      <img src="../../../static/img/environment/1.png" alt="">
      <p class="info">位于兰山经开区的新明辉工业园占地面积50亩，集办公仓储于一体，仓储面积3万余平，园区景色优美，干净整洁。</p>
      <img src="../../../static/img/environment/2.png" alt="">
      <p class="info center">工业园区图</p>
      <img src="../../../static/img/environment/3.png" alt="">
      <p class="info center">工业园仓库</p>
      <img src="../../../static/img/environment/4.png" alt="">
      <p class="info center">工业园仓库</p>
      <img src="../../../static/img/environment/5.png" alt="">
      <p class="info">新明辉第二办公区位于工业大道与与聚才路交汇轻纺劳保城B区127、128号，这里是全国最大的劳保市场集群，新明辉实体体验店内设置多个劳保产品体验区域，顾客可在店内完成一站式体验。</p>
      <img src="../../../static/img/environment/6.png" alt="">
      <p class="info">新明辉第二办公区位于工业大道与与聚才路交汇轻纺劳保城B区127、128号，这里是全国最大的劳保市场集群，新明辉实体体验店内设置多个劳保产品体验区域，顾客可在店内完成一站式体验。</p>
      <img src="../../../static/img/environment/7.png" alt="">
      <p class="info">占地近3000平的宽阔空间，追求简约之感，既便捷了员工之间的交流，打破呆板，充满活力，营造出了空间透亮、环境宜人的办公环境！</p>
      <img src="../../../static/img/environment/8.png" alt="">
      <p class="info">虽然工作节奏较快，但是新明辉的办公室依然兼顾“人文”，设计了非常多的暖心元素，提供更好的环境让员工安心工作~</p>
      <img src="../../../static/img/environment/9.png" alt="">
      <p class="info">在建的新明辉智慧仓储物流园，占地230余亩，办公大楼，会展中心、智慧仓储中心、人才公寓，应有尽有，新明辉期待您的加入，一起共创无限未来！</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Environment",
  data(){
    return {
      isShow : true
    }
  },
  created() {
    let i = window.history.length;
    if(i == 1){
      this.isShow = false;
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  .main {
    box-sizing: border-box;
    padding:0 16px 25px;
    h2 {
      font-family: PingFang-SC-Bold;
      font-size: 23px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #333333;
      text-align: center;
      margin-top: 12px;
    }

    .info {
      font-family: PingFang-SC-Medium;
      line-height: 23px;
      text-align: justify;
      text-indent: 2em;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: 0px;
      color: #666666;
      margin: 20px 0;
    }
    img{
      display: block;
      width: 100%;
      border-radius: 10px;
    }
    .center{
      text-align: center;
    }
  }
}

::v-deep .van-nav-bar__arrow {
  font-size: 19px;
  color: #666666;
}

::v-deep .van-nav-bar__text {
  font-family: PingFang-SC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}
</style>
